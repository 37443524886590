<template>
    <div>
        <v-row>
            <v-col cols="8">
                <s-crud
                    title="Tuneles de camara"
                    height="auto"
                    :config="config"
                    :filter="filter"
                    add 
                    @save="save($event)"
                    edit 
                    remove 
                    search-input
                    @rowSelected="selectTnl($event)">
                    <template v-slot="props">
                        <v-container>
                            <v-row>                        
                                <v-col cols="3" lg="3" md="6" sm="12">
                                    <s-text
                                        label="Descripcion"
                                        v-model="props.item.CdtDescripcion"
                                        ref="txtCdtDescripcion">
                                    </s-text> 
                                </v-col>
                                <v-col cols="3" lg="3" md="6" sm="12">
                                    <s-text
                                        label="Capacidad Carritos"
                                        v-model="props.item.CdtCapacity"
                                        ref="PtnCdtCapacity">
                                    </s-text>
                                </v-col>
                                <v-col cols="3" lg="3" md="6" sm="12">
                                    <s-text
                                        label="Capacidad Toneladas"
                                        v-model="props.item.CdtCapacityTon"
                                        ref="txtCdtCapacityTon">
                                    </s-text>
                                </v-col>                      
                                <v-col cols="3" lg="3" md="6" sm="12"> 
                                    <s-select-definition 
                                        :def=1154 label="Estado" 
                                        v-model=props.item.CdtStatus>
                                    </s-select-definition>  
                                </v-col>
                                <v-col cols="3" lg="3" md="6" sm="12">
                                    <s-text
                                        label="Cantidad de Columnas"
                                        v-model.number="props.item.CdtNumberColumn"
                                        number
                                        ref="txtCdtCapacityTon">
                                    </s-text>
                                </v-col>
                                <v-col cols="3" lg="3" md="6" sm="12">
                                    <s-text
                                        label="Carriles"
                                        v-model.number="props.item.CdtLane"
                                        number
                                        ref="txtCdtCapacityTon">
                                    </s-text>
                                </v-col>
                                <v-col cols="3" lg="3" md="6" sm="12">
                                    <s-text
                                        label="Numero de Lados"
                                        v-model.number="props.item.CdtNumberSides"
                                        number
                                        ref="txtCdtCapacityTon">
                                    </s-text>
                                </v-col>
                                <v-col cols="3" lg="3" md="6" sm="12">
                                    <s-select-definition 
                                        :def=1450 label="Almacenes de congelado" 
                                        v-model="props.item.CdtWarehouses">
                                    </s-select-definition>  
                                </v-col>
                            </v-row>
                        </v-container>
                    </template>
                    <template v-slot:CdtStatus="{ row }">
                        <v-icon :color="row.CdtStatus == 1 ? 'success': 'default'">mdi-checkbox-blank-circle</v-icon>
                    </template>
                </s-crud>
            </v-col>
            <v-col  cols="4">
                <s-crud
                    title="Niveles"
                    height="auto"
                    :config="configLevel"
                    :filter="filterLevel"
                    add 
                    @save="saveLevel($event)"
                    edit 
                    remove 
                    search-input>
                    <template v-slot="props">
                        <v-container>
                            <v-row>                        
                                <v-col cols="6" >
                                    <s-text
                                        label="Descripcion"
                                        v-model="props.item.Dscription"
                                        ref="txtDscription">
                                    </s-text> 
                                </v-col>
                                <v-col cols="6"  >
                                    <s-text
                                        label="Nivel"
                                        v-model.number="props.item.CdlLevel"
                                        number
                                        ref="PtnCdlLevel">
                                    </s-text>
                                </v-col>
                                                      
                                <v-col cols="6"  > 
                                    <s-select-definition 
                                        :def=1154 label="Estado" 
                                        v-model=props.item.CdlStated>
                                    </s-select-definition>  
                                </v-col>
                                <v-col cols="6" >	
                                    <s-text 
                                        label="Color"
                                        v-model="props.item.CdlColor"
                                        type="color"
                                        ref="txtColor"
                                    />	  
                                </v-col>
                            </v-row>
                        </v-container>
                    </template>
                    <template v-slot:CdlStated="{ row }">
                        <v-icon :color="row.CdlStated == 1 ? 'success': 'default'">mdi-checkbox-blank-circle</v-icon>
                    </template>
                    <template v-slot:CdlColor="{ row }">
				<v-chip small :color="row.CdlColor"></v-chip>
        	</template>
                </s-crud>
            </v-col>
        </v-row>
        
    </div>
</template>
<script>

import _sPackingTunnel from '@/services/FrozenProduction/FrzChamberDispatchTunnels.js';
import _sCdtLevel from '@/services/FrozenProduction/FrzchamberdispatchtunnelsLevelService';
 
    export default{
        data() {
            return{
                config:{
                    model:{
                        CdtID: "ID",
                        CdtStatus: "CdtStatus"
                    }
                    ,service: _sPackingTunnel,
                    headers:[
                        {text:"ID", value:"CdtID"},
                        {text:"Descripcion", value:"CdtDescripcion"},
                        {text:"Capacidad", value:"CdtCapacity"},
                        {text:"Capacidad Ton.", value:"CdtCapacityTon"},
                        {text:"Columnas", value:"CdtNumberColumn"},
                        {text:"Carriles", value:"CdtLane"},
                        {text:"Lados", value:"CdtNumberSides"},
                        {text:"Almacen", value:"CdtWareHousesName"},                        
                        // {text:"Tipo Container", value: "TypeProcessContainersDescription"},
                        {text:"Estado", value:"CdtStatus", align:"center", sorteable:true},
                    ]
                },
                filter:{},
                configLevel:{
                    model:{
                        CdlID: "ID",
                        CdlStated: "CdlStated",
                        CdlColor: "CdlColor"
                    }
                    ,service: _sCdtLevel,
                    headers:[
                        {text:"ID", value:"CdlID"},
                        {text:"Descripcion", value:"Dscription"},
                        {text:"Nivel", value:"CdlLevel"},
                        {text:"Estado", value:"CdlStated"},
                        {text:"Color", value:"CdlColor"},
                        // {text:"Tipo Container", value: "TypeProcessContainersDescription"},
                        // {text:"Estado", value:"CdtStatus", align:"center", sorteable:true},
                    ]
                },
                filterLevel:{
                    CdtID: 0,
                }
            }
        },
        methods:{ 
            save(item)
            {
                if(item.PtnDescripcion == ""){
                    this.$fun.alert("Ingrese descripcion", "warning");
                    this.$refs.txtCdtDescripcion.focus();
                    return;
                }
                item.UsrID =  this.$fun.getUserID();
                item.UsrUpdate =  this.$fun.getUserID();
                item.save();
            },
            saveLevel(item){
                item.CdtID  =  this.filterLevel.CdtID;
                item.save();
            },
            selectTnl(items){
                if (items.length > 0) {
                    this.filterLevel.CdtID  = items[0].CdtID;                   
                }
            }
        }
    }

</script>